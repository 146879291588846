import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSelector } from 'reselect';

/**
 * A Redux slice for the WordPress `opportunity` post type.
 */

/**
 * @typedef {object} Filters - The filters.
 * @property {object} opportunityType - Type of opportunity
 * @property {boolean} opportunityType.offers - Offers
 * @property {boolean} opportunityType.requests - Requests
 * @property {object} qualityAndOrigin - Quality & origin
 * @property {boolean|null} qualityAndOrigin.biodiversityContracts - Biodiversity contracts
 * @property {boolean|null} qualityAndOrigin.bioCertifiedFarm - Bio certified farm
 * @property {boolean|null} qualityAndOrigin.pesticideFree - Pesticide free
 * @property {boolean|null} qualityAndOrigin.reducedFertilization - Reduced fertilization
 * @property {object} packagingAndSize - Packaging & size
 * @property {boolean|null} packagingAndSize.bulk - Bulk
 * @property {object} packagingAndSize.bulkType - Bulk: format
 * @property {boolean} packagingAndSize.bulkType.bag - Bag
 * @property {boolean} packagingAndSize.bulkType.bigBag - Big bag
 * @property {boolean|null} packagingAndSize.squareBales - Square bales
 * @property {boolean|null} packagingAndSize.roundBales - Round bales
 * @property {object} mowingDate - Mowing date
 * @property {string|null} mowingDate.startDate - Start date
 * @property {string|null} mowingDate.endDate - End date
 * @property {object} price - Price
 * @property {number|null} price.maxPerUnit - Max price per unit
 * @property {number|null} price.maxPerKilo - Max price per kilo
 * @property {object} additionalOptions - Additional filters
 * @property {boolean|null} additionalOptions.storage - Bulk
 * @property {object} additionalOptions.storageType - Storage
 * @property {boolean} additionalOptions.storageType.dry - Dry
 * @property {boolean} additionalOptions.storageType.ventilated - Ventilated
 * @property {boolean} additionalOptions.storageType.outside - Outside
 * @property {boolean|null} additionalOptions.delivery - Delivery
 */

export const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    /** @type {Filters} */
    filters: {
      opportunityType: {
        offers: true,
        requests: true,
      },
      qualityAndOrigin: {
        biodiversityContracts: null,
        bioCertifiedFarm: null,
        pesticideFree: null,
        reducedFertilization: null,
      },
      packagingAndSize: {
        bulk: null,
        bulkType: {
          bag: true,
          bigBag: true,
        },
        squareBales: null,
        roundBales: null,
      },
      mowingDate: {
        startDate: null,
        endDate: null,
      },
      price: {
        maxPerUnit: null,
        maxPerKilo: null,
      },
      additionalOptions: {
        storage: null,
        storageType: {
          dry: true,
          ventilated: true,
          outside: true,
        },
        delivery: null,
      },
    },
  },
  reducers: {
    /**
     * Save the list
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object[]} action.payload  The list
     */
    saveList: (state, action) => {
      state.list = action.payload;
      state.isLoaded = true;
      process.env.NODE_ENV === 'development' && console.info(state.list);
    },

    /**
     * Save the loading error
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the given filter
     *
     * @param {object} state  The Redux state
     * @param {object} action  The reducer action
     * @param {object} action.payload  The reducer data
     * @param {string} action.payload.group  The filter group
     * @param {string|null} action.payload.field  The filter name
     * @param {boolean|object} action.payload.value  The filter value
     */
    saveFilter: (state, { payload: { group, field, value } }) => {
      process.env.NODE_ENV === 'development' && console.info('saveFilter', group, field, value);
      if (group.includes('.')) {
        set(state.filters, `${group}.${field}`, value);
      } else {
        if (field !== null) {
          state.filters[group][field] = value;
        } else {
          state.filters[group] = value;
        }
      }
    },
  },
});

export const { saveList, saveError, saveFilter } = opportunitiesSlice.actions;

/**
 * Return the opportunities list.
 *
 * @returns {object[]}
 */
export const selectList = createSelector(
  (state) => state.opportunities,
  (opportunities) => opportunities.list
);

export default opportunitiesSlice.reducer;
