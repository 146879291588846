import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * <Loader />
 */

const Loader = (props) => {
  process.env.NODE_ENV === 'development' && console.info('<Loader />');

  const { t: __ } = useTranslation();

  const isLoaded = useSelector((state) => state.app.isContentLoaded);

  /** Set the loader text */
  useEffect(() => {
    document.querySelector('#loader .loader-text').innerHTML = __('app.Loading');
  }, [__]);

  /** Handle the is-loading / is-loaded class names of the loader & the body */
  useEffect(() => {
    const body = document.querySelector('body');
    const loader = document.getElementById('loader');
    if (!isLoaded) {
      body.classList.add('is-loading');
      loader.classList.remove('is-loaded');
      setTimeout(() => {
        loader.classList.add('is-loading');
      }, 50);
    } else {
      body.classList.remove('is-loading');
      loader.classList.remove('is-loading');
      /** Add a delay to match the css transition in _loader.scss */
      setTimeout(() => {
        loader.classList.add('is-loaded');
      }, 400);
    }
  }, [isLoaded]);

  return <></>;
};

/**
 * <SectionLoader />
 *
 * This component is to be used within a <Section> layout
 */

export const SectionLoader = ({ isLoaded }) => {
  const { t: __ } = useTranslation();
  const [isLoaderRemoved, setIsLoaderRemoved] = useState(false);

  const loader = document.getElementById('loader').cloneNode(true);
  loader.querySelector('.loader-text').innerHTML = __('app.Loading');

  useEffect(() => {
    isLoaded && setTimeout(() => setIsLoaderRemoved(true), 500);
  }, [isLoaded]);

  return (
    <>
      {!isLoaderRemoved ? (
        <div
          className={`loader section-loader ${!isLoaded ? 'is-loading' : ''}`}
          dangerouslySetInnerHTML={{ __html: loader.innerHTML }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

SectionLoader.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
};

export default Loader;
