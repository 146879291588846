import PropTypes from 'prop-types';

/*******
 * APP *
 *******/

/**
 * The colors of the app
 */
const colors = ['primary', 'secondary', 'sage', 'cream'];
export const colorPropTypes = PropTypes.oneOf(['white', ...colors]);
export const colorPropTypesNoWhite = PropTypes.oneOf(colors);
export const buttonColorPropTypes = PropTypes.oneOf(['white', 'thyme', 'rosewood', ...colors]);

/**
 * The image formats of the app -- Must match the custom image sizes settings in /app/setup.php
 */
export const imagePropTypes = {
  /** Image alt */
  alt: PropTypes.string,
  /** Landscape */
  landscape: PropTypes.shape({
    w3840h2560: PropTypes.string,
    w2880h1920: PropTypes.string,
    w1920h1280: PropTypes.string,
    w1440h960: PropTypes.string,
    w960h640: PropTypes.string,
  }),
  /** Portrait */
  portrait: PropTypes.shape({
    w2560h3840: PropTypes.string,
    w1920h2880: PropTypes.string,
    w1280h1920: PropTypes.string,
    w960h1440: PropTypes.string,
  }),
  /** Square */
  square: PropTypes.shape({
    w2560h2560: PropTypes.string,
    w1920h1920: PropTypes.string,
    w1440h1440: PropTypes.string,
    w960h960: PropTypes.string,
  }),
  /** Resized */
  resized: PropTypes.shape({
    w3840: PropTypes.string,
    w2880: PropTypes.string,
    w1920: PropTypes.string,
    w1440: PropTypes.string,
    w960: PropTypes.string,
  }),
  /** Full */
  full: PropTypes.string,
  /** Thumbnail */
  thumbnail: PropTypes.string,
};

/**
 * The base props of a link
 */
export const linkPropTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};

/**
 * Props of a location lat-lng
 */
export const latLngPropTypes = {
  /** The latitude of a location */
  lat: PropTypes.number,
  /** The longitude of a location */
  lng: PropTypes.number,
};

/********
 * Rest *
 ********/

/**
 * Returned by all routes listing pages
 */
export const pagePropTypes = {
  /** The id of the page */
  id: PropTypes.number,
  /** The url of the page */
  url: PropTypes.string.isRequired,
  /** The rest url of the page */
  restUrl: PropTypes.string.isRequired,
  /** The title of the page */
  title: PropTypes.string.isRequired,
  /** The name of the template for the page */
  templateName: PropTypes.string.isRequired,
  /** A slug for the page, regardless the language */
  pageName: PropTypes.string.isRequired,
  /** The id of the parent of the page */
  parentId: PropTypes.number,
  /** The 2-letter language code of the page -- false is provided while the pages are loading */
  lang: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /** An object of translations for the page {'language_code': 'url'} -- array is provided while the pages are loading */
  translations: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.array]).isRequired,
  /** The type of the page -- Added by <AppRouter /> to the <Template /> `page` prop  -- '' is provided while the pages are loading */
  type: PropTypes.oneOf(['page', 'user', '404', '']),
  /** Additional data -- The type is array when there is no data */
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

/**
 * Returned by each single page route
 */
export const contentPropTypes = {
  /** The meta data of the page */
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};
