import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

/** Redux */
import { saveIsContentLoaded } from '../redux/appSlice';

/**
 * useNavigateWithLoader Hook
 *
 * Set the isLoaded app Redux state to false to make the loader appear.
 * Call useNavigate after the loader appear duration or immediatly if the user doesn't want animations.
 * The loader is removed by PagesRouter.jsx when the page content is loaded.
 *
 * @returns {object} {navigate: function, removeLoader: function}  The hook callback functions
 */
const useNavigateWithLoader = () => {
  const currentPage = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * navigateWithLoader callback function
   *
   * @param {string} link  The link to navigate to
   * @param {Function} callback  A callback function to call once the loader is visible
   */
  const navigateWithLoader = useCallback(
    (link, callback) => {
      if (link) {
        process.env.NODE_ENV === 'development' && console.info(`useNavigateWithLoader : ${link}`);
        if (currentPage.pathname !== link) {
          /** Use the loader only for internal links */
          if (link.startsWith('/') || window.location.hostname === new URL(link).hostname) {
            /** Remove the hostname & protocol to match the router routes */
            link.includes(window.location.hostname) && (link = new URL(link).pathname);
            dispatch(saveIsContentLoaded(false));
            if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
              /** Add a delay to match the CSS animation in _loader.scss */
              setTimeout(() => {
                navigate(link);
                callback && callback();
              }, 400);
            } else {
              navigate(link);
              callback && callback();
            }
          } else {
            window.location = link;
          }
        }
      }
    },
    [currentPage, dispatch, navigate]
  );

  /**
   * removeLoader callback function
   */
  const removeLoader = useCallback(() => {
    /** Scroll top */
    window.scrollTo(0, 0);
    dispatch(saveIsContentLoaded(true));
  }, [dispatch]);

  /** Return the hook callback functions */
  return { navigate: navigateWithLoader, removeLoader };
};

export { useNavigateWithLoader };
export default useNavigateWithLoader;
