/**
 * Process the server response.
 *
 * @param {Response} response  The server response
 * @returns {Promise}  A resolved / rejected Promise
 */
export const responseStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    let err = response.statusText !== '' ? `${response.status}: ${response.statusText}` : 'Unknown error.';
    if (response.type === 'opaque') err = 'Problem with the authentication server.';
    return Promise.reject(new Error(err));
  }
};

/**
 * Fetch an url (GET) and parse the returned JSON.
 *
 * @param {string} url  The url to fetch
 * @param {object} headers  The headers to append
 * @returns {Promise<JSON|Error>}  A promise to the parsed json
 */
export const fetchAndParseJSON = (url, headers = {}) =>
  new Promise((resolve, reject) => {
    fetch(url, { headers: { Accept: 'application/json', ...headers } })
      .then(responseStatus)
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });

/**
 * Form sender class.
 *
 * Send a fetch request to the URL supplied as parameter
 * Adds `X-CSRF-TOKEN` header
 * OAuth can be used by setting the `REACT_APP_AUTH_*` parameters in `.env`
 *
 * @function POST
 * @fires response  { sent: bool, data|error: string }
 */
export class FormSender {
  /**
   * Constructor.
   *
   * @param {FormData} formData  The form data to send
   * @param {string} url  The URL to post the form to
   * @param {string} corsDomain  The cross-origin domain
   */
  constructor(formData, url, corsDomain) {
    this.formData = formData;
    this.url = url.startsWith('http') ? url : `${window.location.protocol}//${window.location.host}${url}`;
    this.corsDomain = corsDomain;
    this.headers = new Headers();
    this.listeners = new Map();

    /**
     * Check if OAuth is configured.
     */
    this.useOAuth =
      process.env.REACT_APP_AUTH_TOKEN_URL !== undefined &&
      process.env.REACT_APP_AUTH_TOKEN_URL.length > 0 &&
      process.env.REACT_APP_AUTH_CLIENT_ID !== undefined &&
      process.env.REACT_APP_AUTH_CLIENT_ID.length > 0 &&
      process.env.REACT_APP_AUTH_CLIENT_SECRET !== undefined &&
      process.env.REACT_APP_AUTH_CLIENT_SECRET.length > 0;
  }

  /**
   * Public method to add a header to the post request.
   * It is possible to override default headers from `this.postFormData`.
   *
   * @param {string} name  The header name
   * @param {string} value  The header value
   */
  addHeader(name, value) {
    this.headers.set(name, value);
  }

  /**
   * Public method to add a listener to the sender response.
   *
   * @param {string} label (response)  The event name
   * @param {Function} callback  The callback function
   */
  addListener(label, callback) {
    this.listeners.has(label) || this.listeners.set(label, []);
    this.listeners.get(label).push(callback);
  }

  /**
   * Emit catchable Events.
   *
   * @param {string} label  The event name
   * @param  {...any} args  The event data
   * @returns {boolean}  Whether the event has been emitted
   */
  emit(label, ...args) {
    const listeners = this.listeners.get(label);
    if (listeners && listeners.length) {
      listeners.forEach((listener) => listener(...args));
      return true;
    }
    return false;
  }

  /**
   * Send the form or get the Acces token if OAuth is configured.
   */
  send() {
    this.useOAuth ? this.getAccessToken() : this.postFormData();
  }

  /**
   * Get the OAuth Acces token and post the form.
   */
  getAccessToken() {
    const url = new URL(process.env.REACT_APP_AUTH_TOKEN_URL);
    const headers = new Headers({ Accept: '*/*' });

    /** Append the OAuth parameters to the headers or to the query string */
    if (process.env.REACT_APP_AUTH_GET_TOKEN === 'headers') {
      headers.append(
        'Authorization',
        `OAuth client_id='${process.env.REACT_APP_AUTH_CLIENT_ID}', client_secret='${process.env.REACT_APP_AUTH_CLIENT_SECRET}', grant_type='authorization_code'`
      );
    } else {
      const params = {
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        // eslint-disable-next-line quotes
        grant_type: "'authorization_code'",
      };
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    }

    /** Get the Access token and post the form or emit a `response` event on error */
    fetch(url, { method: 'POST', headers })
      .then(this.responseStatus)
      .then((res) => res.json())
      .then((res) => this.postFormData(res.access_token))
      .catch((error) => this.emit('response', { sent: false, error: error.message }));
  }

  /**
   * Get the CSRF token from whether the meta tags or the form data.
   *
   * @returns {string}  The CSRF token
   */
  getCSRFToken() {
    const tokenMeta = document.querySelector('meta[name="csrf-token"]');
    return tokenMeta !== null ? tokenMeta.getAttribute('content') : this.formData.get('csrf-token');
  }

  /**
   * Post the form.
   *
   * @param {string} accessToken  The OAuth Access token
   */
  postFormData(accessToken = null) {
    const url = new URL(this.url);
    const headers = new Headers({
      Accept: '*/*',
      'X-CSRF-TOKEN': this.getCSRFToken(),
    });

    /** Append user-added headers */
    this.headers.forEach((value, name) => headers.set(name, value));

    /** Append the Cross-Origin header */
    if (this.corsDomain !== undefined) {
      headers.append('Access-Control-Allow-Origin', this.corsDomain);
    } else {
      headers.append('Access-Control-Allow-Origin', `${window.location.protocol}//${window.location.host}`);
    }

    /** Appennd the OAuth parameters to the headers or to the query string */
    if (this.useOAuth) {
      if (process.env.REACT_APP_AUTH_POST_FORMDATA === 'headers') {
        headers.append('Authorization', `Bearer ${accessToken}`);
      } else {
        const params = {
          Bearer: accessToken,
        };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
      }
    }

    /** Post the form and emit `response` events on sent and on error */
    fetch(url, {
      method: 'POST',
      headers,
      body: this.formData,
    })
      .then(this.responseStatus)
      .then((res) => res.json())
      .then((res) => this.emit('response', { sent: true, data: res }))
      .catch((error) => this.emit('response', { sent: false, error: error.message }));
  }
}
