import { configureStore } from '@reduxjs/toolkit';

import appReducer from './appSlice';
import userReducer from './userSlice';
import optionsReducer from './optionsSlice';
import pagesReducer from './pagesSlice';
import opportunitiesReducer from './opportunitiesSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    options: optionsReducer,
    pages: pagesReducer,
    opportunities: opportunitiesReducer,
  },
});
